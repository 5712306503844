<template>
  <div class="item-desktop">
    <div class="item-desktop__team">
      <div class="item-desktop__team__side-button" :class="{ 'item-desktop__team__side-button--hidden': game.isFirst }">
        <CarouselButton
          @click="
            $emit('click', $event);
            onClickLeft();
          "
        />
      </div>
      <CarouselTeamInfo
        :team="game.homeTeam"
        :is-profile-button-visible="game.hasStatistics"
        @click-profile="onClickProfile('homeTeam')"
      />
    </div>
    <div class="item-desktop__date-time-and-statistics">
      <CarouselDateTime :game-date-time="game.date_time_utc" />
      <StatisticsButton
        v-if="game.hasStatistics"
        @click="
          $emit('show-statistics');
          onClickSeeStats();
        "
      />
    </div>
    <div class="item-desktop__team item-desktop__team-away">
      <CarouselTeamInfo
        :isHomeTeam="false"
        :team="game.awayTeam"
        :is-profile-button-visible="game.hasStatistics"
        @click-profile="onClickProfile('awayTeam')"
      />
      <div class="item-desktop__team__side-button" :class="{ 'item-desktop__team__side-button--hidden': game.isLast }">
        <CarouselButton
          type="right"
          @click="
            $emit('click', $event);
            onClickRight();
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  RightBetCarrouselViewProfile,
  RightBetCarrouselNext,
  RightBetCarrouselBefore,
  RightBetCarrouselViewStats,
} from '@/utils/analytics';
export default {
  name: 'CarouselItemDesktop',
  components: {
    CarouselButton: () => import('@/components/Sections/GolTipster-v2/General/CarouselButton'),
    CarouselTeamInfo: () => import('@/components/Sections/GolTipster-v2/General/CarouselTeamInfo'),
    CarouselDateTime: () => import('@/components/Sections/GolTipster-v2/General/CarouselGameDateTime'),
    StatisticsButton: () => import('@/components/Sections/GolTipster-v2/General/GoToStatisticsButton'),
  },
  props: {
    game: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
  },
  methods: {
    onClickRight() {
      RightBetCarrouselNext({ is_premium: this.getPremiumAccount, golstats: this.getIsGolstats });
    },
    onClickLeft() {
      RightBetCarrouselBefore({ is_premium: this.getPremiumAccount, golstats: this.getIsGolstats });
    },
    onClickProfile(teamType) {
      RightBetCarrouselViewProfile({
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
        team_id: this.game[teamType].team_id,
        season_id: this.game.seasonId,
        game_id: this.game.game_id,
      });
      if (this.$route.name === 'viewAll') {
        this.$router.push({
          name: 'teamProfile',
          query: { team: this.game[teamType].team_id, season: this.game.seasonId, isLanding: true },
        });
      } else {
        let routeData = this.$router.resolve({
          name: 'teamprofile',
          query: { team: this.game[teamType].team_id, season: this.game.seasonId },
        });
        window.open(routeData.href, '_blank');
      }
    },
    onClickSeeStats() {
      RightBetCarrouselViewStats({
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
        season_id: this.game.seasonId,
        game_id: this.game.game_id,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.item-desktop {
  width: 100%;
  display: grid;
  grid-template-columns: 38% 24% 38%;
  padding: 0 1rem;
  background-image: url('/images/base-desktop.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 12.5rem;
  border-radius: 24px;

  &__team {
    display: flex;
    align-items: center;

    &-away {
      justify-self: right;
    }

    &__side-button {
      margin: 0 0.8rem;

      &--hidden {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  &__date-time-and-statistics {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2.3rem 0 2.5rem;
  }
}
</style>
